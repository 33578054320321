const navItems = {
    super: {
        children: [
            {
                id: 'org',
                title: 'Organizations',
                navLink: '/organizations'
            },
            // {
            //     id: 'settings',
            //     title: 'Settings',
            //     navLink: '/settings'
            // }
        ]
    },
    admin: {
        children: [{
            id: 'companies',
            title: 'Companies',
            navLink: '/companies'
        }]
    }
}
export default navItems