import { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { RouteList } from './routes/index';
import RequireAuth from './components/requireAuth';
import Loader from './components/loader';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import './custom.css';

function App() {
  return (
    <BrowserRouter>
    
      <Suspense fallback={<Loader />}>
        <Routes>
          {RouteList.map((route, index) =>
            <Route key={index} path={route.path} exact element={route.auth ?
              <RequireAuth>
                <route.element />
              </RequireAuth>
              : <route.element />} />
          )}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
