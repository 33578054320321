import { lazy } from 'react'

const routes = [
    {
        path: '/organizations',
        element: lazy(() => import('../pages/superAdmin/index')),
        auth: true
    },
    {
        path: '/add-org',
        element: lazy(() => import('../pages/superAdmin/AddOrganization')),
        auth: true
    },
    {
        path: '/edit-org/:id',
        element: lazy(() => import('../pages/superAdmin/AddOrganization')),
        auth: true
    }
]
export default routes
