import { memo } from 'react';
import { Navigate } from 'react-router-dom';
import AuthHelper from '../../utils/AuthHelper';
import NavMenu from '../navMenu';

const RequireAuth = ({ children }) => {
  if (!AuthHelper.isLoggedIn()) {
    return <Navigate to="/login" />;
  }

  return (
    <>
      <div className="main-content">
        <NavMenu />
        <div className="content-area">
          <div className="container content-container">{children}</div>
        </div>
      </div>
    </>
  );
};

export default memo(RequireAuth);
