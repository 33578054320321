import CounterNamesObj from 'react-phone-number-input/locale/en.json';
import {
  getCountries,
  getCountryCallingCode
} from 'react-phone-number-input/input';
class SettingsHelper {
  static #settingKey = 'WebSetting';

  /**
   * Set the WebSetting value
   *
   * @param {string} webSetting
   * @returns {void}
   */
  static setWebSetting(webSetting) {
    try {
      localStorage.setItem(this.#settingKey, JSON.stringify(webSetting));
    } catch (errorObj) { }
  }

  /**
   * Getting the WebSetting
   *
   * @param {string} objectkey Setting Key
   *
   * @returns {string}
   */
  static getSettingValue(objectkey) {
    try {
      const str1 = localStorage.getItem(this.#settingKey);
      const settingObj = JSON.parse(str1);
      if (
        typeof objectkey === 'string' &&
        objectkey.trim() !== '' &&
        typeof settingObj?.[objectkey] !== 'undefined'
      ) {
        return settingObj[objectkey];
      }

      return '';
    } catch (errorObj) {
      return '';
    }
  }

  /**
   * Clearing the WebSetting from local storage
   *
   * @returns {void}
   */
  static clearSettingKey() {
    return localStorage.removeItem(this.#settingKey);
  }


  /**
    * Get phone codes of each country
    *
    * @returns {Array}
    */
  static getPhoneCodes = () => {
    try {
      let countryCode;
      let results = [];
      for (countryCode of getCountries()) {
        if (
          typeof getCountryCallingCode(countryCode) !== 'undefined' &&
          typeof CounterNamesObj?.[countryCode] === 'string' &&
          CounterNamesObj[countryCode].trim() !== ''
        ) {
          results.push({
            code: '+' + getCountryCallingCode(countryCode),
            label:
              '+' +
              getCountryCallingCode(countryCode) +
              ' ' +
              CounterNamesObj[countryCode].trim()
          });
        }
      }
      return results
    } catch (errorObj) {
      return []
    }
  }
};

export default SettingsHelper;
