import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from "redux";
import editDataReducer from './slices/editDataSlice';
import notificationMessageReducer from './slices/notificationMessageSlice';
import userDataSlice from './slices/userDataSlice';
import companyDataSlice from './slices/companyDataSlice';
import storage from 'redux-persist/lib/storage';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';

const appReducer = combineReducers({
  editDataReducer,
  notificationMessageReducer,
  userData: userDataSlice,
  companyData: companyDataSlice
});

const reducer = (state, action) => {
  if (action.type === 'CLEAR_STORE') {
    return appReducer(undefined, action)
  } else return appReducer(state, action)
}


const persistConfig = {
  key: 'root',
  version: 1,
  storage
};

const persistedReducer = persistReducer(persistConfig, reducer);


export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
