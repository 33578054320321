import { lazy } from 'react'

const routes = [
    {
        path: '/',
        element: lazy(() => import('../pages/login')),
        auth: false
    },
    {
        path: '/login',
        element: lazy(() => import('../pages/login')),
        auth: false
    },
    {
        path: '/dashboard',
        element: lazy(() => import('../pages/dashboard')),
        auth: true
    }
]
export default routes
