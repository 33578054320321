import { memo } from 'react';

const Loader = () => {
  return (
    <>
      <div className="d-flex justify-content-center align-items-center">
        <div className="spinner-border" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
    </>
  );
};

export default memo(Loader);
