import moment from "moment";

const Footer = ({ name }) => (
    <div className="sidebar-footer d-block">
        <label>
            &#169; {moment(new Date()).format('YYYY')} {name}.
        </label>
        <label>All rights reserved.</label>
    </div>

)
export default Footer