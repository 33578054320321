/* eslint-disable react-hooks/exhaustive-deps */

import { clearUser } from '../../redux/slices/userDataSlice';
import { Link, useLocation } from 'react-router-dom';
import { memo, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ArrowRightImg from '../../assets/images/arrow-right.svg';
import AuthHelper from '../../utils/AuthHelper';
import CompanyNav from './CompanyNav';
import Footer from '../footer/Footer';
import jquery from 'jquery';
import Logout from '../logout/Logout';
import navItems from './navItems';
import SettingsHelper from '../../utils/SettingsHelper';
import { pascalText } from '../../utils/CommonUtility';
import { clearCompany } from '../../redux/slices/companyDataSlice';

const NavMenu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [isLeftMenuOpen, setIsLeftMenuOpen] = useState(false);
  const location = useLocation();
  const [isLogoutShow, setIsLogoutShow] = useState(false);
  const { username, role } = JSON.parse(localStorage.getItem('User'));
  const { id } = useParams()
  /**
   * Handle logout
   */
  const handleLogout = () => {
    AuthHelper.clearAuthToken();
    AuthHelper.clearUser();
    localStorage.removeItem('staffSlug')
    SettingsHelper.clearSettingKey();
    setIsLogoutShow(false);
    navigate('/login');
    dispatch(clearUser())
    dispatch(clearCompany())
  };

  return (
    <>
      <div className={`leftside-bar ${isLeftMenuOpen ? 'open' : ''}`}>
        <div
          className="leftside-collapse d-md-none d-block shadow-sm"
          id="filter"        >
          <button
            className="mob-menu"
            onClick={(event) => {
              event.preventDefault();
              setIsLeftMenuOpen(!isLeftMenuOpen);
              jquery('body').toggleClass('fixed');
            }}>
            <div className="mobilemenu">
              <div className="arrow-right">
                <img src={ArrowRightImg} alt="Right Arrow" width="20" />
              </div>
            </div>
          </button>
        </div>
        <div className="sidebar-menu">
          <div className="fs-6 fw-semibold">WELCOME {username.toUpperCase()}</div>
          <ul className="list-unstyled">
            {role === 1 && navItems.super.children.map((item) =>
            (
              <li className="sidemenu-items" key={item.id}>
                <Link
                  to={item.navLink}
                  className={location?.pathname === item.navLink ? 'active' : ''}                >
                  {item.title}
                </Link>
              </li>
            ))
            }
            {role === 2 && navItems.admin.children.map((item) =>
            (
              <li className="sidemenu-items" key={item.id}>
                <Link
                  to={item.navLink}
                  className={location?.pathname === item.navLink ? 'active' : ''}                >
                  {item.title}
                </Link>
              </li>
            ))
            }
            {
              location.pathname.includes('company-details') && <CompanyNav location={location} roles={role} />
            }
            <li className="sidemenu-items" key="menu8">
              <Logout companyId={''} isLogoutShow={isLogoutShow} setIsLogoutShow={setIsLogoutShow} handleLogout={handleLogout} />
            </li>
          </ul>
          <Footer name="Cubet Techno Labs" />
        </div>
      </div>
    </>
  );
};

export default memo(NavMenu);
