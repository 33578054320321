const Logout = ({ isLogoutShow, setIsLogoutShow = () => { }, handleLogout = () => { } }) => (
    <>
        {!isLogoutShow && (
            <a
                className="logoutDialogBox"
                href="'#'"
                onClick={(event) => {
                    event.preventDefault();
                    setIsLogoutShow(!isLogoutShow);
                }}>
                Logout
            </a>
        )}
        {isLogoutShow && (
            <div className="item-boxes mb-2 logoutDialogBox p-2 ">
                Do you want to logout?
                <div className="mt-2">
                    <button
                        onClick={async (event) => {
                            event.preventDefault();
                            handleLogout();
                        }}
                        className="item-box-content btn-primary bg-danger border-0 w-3r" >
                        Yes
                    </button>
                    <button
                        onClick={(event) => {
                            event.preventDefault();
                            setIsLogoutShow(!isLogoutShow);
                        }}
                        className="item-box-content btn-primary border-0 w-3r">
                        No
                    </button>
                </div>
            </div>
        )}
    </>

)
export default Logout