import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: { full_name: '' }
};

export const userDataSlice = createSlice({
  name: 'setUser',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    clearUser: (state, action) => {
      state.user = {}
    }
  }
});

export const { setUser, clearUser } = userDataSlice.actions;

export default userDataSlice.reducer;
