import { lazy } from 'react'

const routes = [
    {
        path: '/company-details/:id',
        element: lazy(() => import('../pages/company/CompanyDetails')),
        auth: true
    },
    {
        path: '/organization/:orgId/company-details/:id',
        element: lazy(() => import('../pages/company/CompanyDetails')),
        auth: true
    },
    {
        path: '/add-company',
        element: lazy(() => import('../pages/admin/AddCompany')),
        auth: true
    },
    {
        path: '/organization/:orgId/add-company',
        element: lazy(() => import('../pages/admin/AddCompany')),
        auth: true
    },
    {
        path: '/edit-company/:id',
        element: lazy(() => import('../pages/admin/AddCompany')),
        auth: true
    },
    {
        path: '/organization/:orgId/edit-company/:id',
        element: lazy(() => import('../pages/admin/AddCompany')),
        auth: true
    },
    {
        path: '/company-details/edit-company/:id',
        element: lazy(() => import('../pages/admin/AddCompany')),
        auth: true
    },
    {
        path: '/organization/:orgId/company-details/edit-company/:id',
        element: lazy(() => import('../pages/admin/AddCompany')),
        auth: true
    },
    {
        path: '/company-details/edit-settings/:id',
        element: lazy(() => import('../pages/admin/AddCompany')),
        auth: true
    },
    {
        path: '/company-details/staff',
        element: lazy(() => import('../pages/staff/index')),
        auth: true
    },
    {
        path: '/company-details/add-staff',
        element: lazy(() => import('../pages/staff/add-staff-stepper/index')),
        auth: true
    },
    {
        path: '/company-details/edit-staff/:id',
        element: lazy(() => import('../pages/staff/add-staff-stepper/index')),
        auth: true
    },
    {
        path: '/company-details/staff-details/:id',
        element: lazy(() => import('../pages/staff/StaffDetails')),
        auth: true
    },
    {
        path: '/company-details/customer',
        element: lazy(() => import('../pages/customer/index')),
        auth: true
    },
    {
        path: '/company-details/add-customer',
        element: lazy(() => import('../pages/customer/AddCustomer')),
        auth: true
    },
    {
        path: '/company-details/edit-customer/:id',
        element: lazy(() => import('../pages/customer/AddCustomer')),
        auth: true
    },
    {
        path: '/company-details/customer-details/:id',
        element: lazy(() => import('../pages/customer/CustomerDetails')),
        auth: true
    },
    {
        path: '/company-details/template-category',
        element: lazy(() => import('../pages/template-category/index')),
        auth: true
    },
    {
        path: '/company-details/add-template-category',
        element: lazy(() => import('../pages/template-category/AddCategory')),
        auth: true
    },
    {
        path: '/company-details/edit-template-category/:id',
        element: lazy(() => import('../pages/template-category/AddCategory')),
        auth: true
    },
    {
        path: '/company-details/templates',
        element: lazy(() => import('../pages/templates/index')),
        auth: true
    },
    {
        path: '/company-details/add-template',
        element: lazy(() => import('../pages/templates/AddTemplate')),
        auth: true
    },
    {
        path: '/company-details/edit-template/:id',
        element: lazy(() => import('../pages/templates/AddTemplate')),
        auth: true
    },
    {
        path: '/company-details/schedule',
        element: lazy(() => import('../pages/schedules/index')),
        auth: true
    },
    {
        path: '/company-details/add-schedule',
        element: lazy(() => import('../pages/schedules/AddSchedule')),
        auth: true
    },
    {
        path: '/company-details/edit-schedule/:id',
        element: lazy(() => import('../pages/schedules/AddSchedule')),
        auth: true
    },


]
export default routes
