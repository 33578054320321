import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: undefined
};

export const editDataSlice = createSlice({
  name: 'editData',
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload.data;
    }
  }
});

export const { setData } = editDataSlice.actions;

export default editDataSlice.reducer;
