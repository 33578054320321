import jwtDecode from 'jwt-decode';
class AuthHelper {
  static #authTokenKey = 'AuthToken';
  static #user = 'User';

  /**
   * Set the AuthToken value
   *
   * @param {string} token
   * @returns {void}
   */
  static setAuthToken(token) {
    return localStorage.setItem(this.#authTokenKey, token);
  }

  /**
  * Set the user data
  *
  * @param {string} token
  * @returns {void}
  */
  static setUser(user) {
    return localStorage.setItem(this.#user, JSON.stringify(user));
  }

  /**
   * Getting the AuthToken
   *
   * @returns {string}
   */
  static getAuthToken() {
    return localStorage.getItem(this.#authTokenKey);
  }

  /**
 * Getting the AuthToken
 *
 * @returns {string}
 */
  static getLoggedInUserRole() {
    const { role } = JSON.parse(localStorage.getItem('User'));
    return role
  }

  /**
   * Clearing the AuthToken from local storage
   *
   * @returns {void}
   */
  static clearAuthToken() {
    return localStorage.removeItem(this.#authTokenKey);
  }

  /**
  * Clearing the user data from local storage
  *
  * @returns {void}
  */
  static clearUser() {
    return localStorage.removeItem(this.#user);
  }

  /**
   * It is used for check whether a user is logged in or not
   *
   * @returns {boolean}
   */
  static isLoggedIn() {
    try {
      const token = this.getAuthToken();
      jwtDecode(token);
      if (jwtDecode(token).exp < Date.now() / 1000) {
        localStorage.clear()
      }
      return true;
    } catch (errorObj) {
      return false;
    }
  }


  /**
   * It is used to get the dashboard paths
   *
   * @returns {string}
   */
  static gotoDashboard(role) {
    if (role === 1)
      return '/organizations'
    return '/companies'
  }
}



export default AuthHelper;
