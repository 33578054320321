import { lazy } from 'react'

const routes = [
    {
        path: '/companies',
        element: lazy(() => import('../pages/admin/index')),
        auth: true
    },
    {
        path: '/organization/:orgId/companies/',
        element: lazy(() => import('../pages/admin/index')),
        auth: true
    }
]
export default routes
