import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { pascalText } from '../../utils/CommonUtility';

const CompanyNav = ({ location, roles }) => {
  const { pathname } = location;
  const { orgId } = useParams();
  const { company } = useSelector((state) => state.companyData);
  const homePath = () => {
    if (pathname.includes('company-details') && pathname.includes(company.slug))
      return true;
    return false;
  };
  const getOrgId = () => {
    return orgId || JSON.parse(localStorage.getItem('User')).organization?.slug;
  };

  return company ? (
    <>
      <hr />
      <li className="fs-6 fw-semibold" key="company_menu1">
        {company.name.toUpperCase()}
      </li>
     
        <li className="sidemenu-items" key="company_menu2">
          <Link
            to={roles !== 3 ? `/company-details/${company.slug}`:`/company-details/staff-details/${localStorage.getItem('staffSlug')}`}
            className={homePath() ? 'active' : ''}
          >
            Home
          </Link>
        </li>
      {roles !== 3 ? (
        <li className="sidemenu-items" key="company_menu3">
          <Link
            to="/company-details/staff"
            className={pathname.includes('staff') ? 'active' : ''}
          >
            {pascalText(company.staff_designation || 'Staff')}
          </Link>
        </li>
      ) : (
        ''
      )}
      <li className="sidemenu-items" key="company_menu4">
        <Link
          to="/company-details/customer"
          className={pathname.includes('customer') ? 'active' : ''}
        >
          {pascalText(company.customer_designation || 'Customer')}
        </Link>
      </li>
      <li className="sidemenu-items" key="company_menu6">
        <Link
          to="/company-details/template-category"
          className={pathname.includes('category') ? 'active' : ''}
        >
          Template Category
        </Link>
      </li>
      <li className="sidemenu-items" key="company_menu5">
        <Link
          to="/company-details/templates"
          className={pathname.includes('templates') ? 'active' : ''}
        >
          Template
        </Link>
      </li>

      <li className="sidemenu-items" key="company_menu8">
        <Link
          to="/company-details/schedule"
          className={location.pathname.includes('schedule') ? 'active' : ''}
        >
          Schedule
        </Link>
      </li>
      {roles !== 3 ? (
      <li className="sidemenu-items" key="company_menu7">
        <Link
          to={`/organization/${getOrgId()}/company-details/edit-company/${
            company.slug
          }`}
          className={location.pathname.includes('settings') ? 'active' : ''}
        >
          Settings
        </Link>
      </li>):''}
      <hr />
    </>
  ) : (
    ''
  );
};
export default CompanyNav;
