import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  notificationMsg: '',
  msgStatus: ''
};

export const notificationMessageSlice = createSlice({
  name: 'notificationMessage',
  initialState,
  reducers: {
    setNotificationMsg: (state, action) => {
      state.notificationMsg = action.payload;
    },
    setMsgStatus: (state, action) => {
      state.msgStatus = action.payload;
    }
  }
});

export const { setNotificationMsg, setMsgStatus } =
  notificationMessageSlice.actions;

export default notificationMessageSlice.reducer;
