export const getFormData = object => Object.keys(object).reduce((formData, key) => {
    formData.append(key, object[key]);
    return formData;
}, new FormData());

export const pascalText = (string) => {
    return string.replace(/(\w)(\w*)/g,
        function (g0, g1, g2) { return g1.toUpperCase() + g2.toLowerCase(); });
}

export const fadeOutEffect = (el) => {
    if (el) {
        const speed = 1000
        var seconds = speed / 1000;
        el.style.transition = "opacity " + seconds + "s ease";
        el.style.opacity = 0;
        setTimeout(function () {
            el.parentNode.removeChild(el);
        }, speed);
    }
}

export const getRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
}