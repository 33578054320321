// ** Routes Imports
import AdminRoutes from './AdminRoutes';
import CompanyRoutes from './CompanyRoutes';
import LoginRoutes from './LoginRoutes';
import SuperAdminRoutes from './SuperAdminRoutes';

// ** Default Route
const DefaultRoute = '/login';

// ** Merge Routes
const RouteList = [
  ...LoginRoutes,
  ...AdminRoutes,
  ...SuperAdminRoutes,
  ...CompanyRoutes
];

export { DefaultRoute, RouteList };
